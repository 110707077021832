import * as React from 'react';
import { TextProps } from './types';
import { useMpharmaI18n } from './MpharmaI18nProvider';

export function Text({
  i18nKey,
  variables = {},
  children = '',
}: TextProps): JSX.Element {
  const { translateKey } = useMpharmaI18n();
  let localizedString = translateKey(i18nKey, variables);
  if (!localizedString || localizedString === i18nKey) {
    // This usually means there is no viable translation for this key for the current language
    console.warn(
      'This usually means there is no viable translation for this key for the current language'
    );
    localizedString = children;
  }

  return <>{localizedString}</>;
}

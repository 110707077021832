import * as React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { createI18n } from './i18n';
import { MpharmaContextValues, MpharmaI18nProviderProps } from './types';
import { useEffect } from 'react';

const MpharmaI18nContext = React.createContext<MpharmaContextValues>({
  ready: false,
  translateKey: () => {},
  currentLanguage: '',
  changeLanguage: () => {},
  availableLanguages: [],
});

const WrapperComponent: React.FC<{
  availableLanguages: string[];
}> = props => {
  const { i18n, t: translateKey, ready } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    const changeLanguageEvent: CustomEvent<string> = new CustomEvent(
      'mpharmaI18n',
      { detail: language }
    );
    window.dispatchEvent(changeLanguageEvent);
  };
  useEffect(() => {
    const handleLanguageChange = (e: StorageEvent) => {
      if (e.key === 'mpharmaI18n' && e.newValue) {
        i18n.changeLanguage(e.newValue);
      }
    };

    const handleCustomLanguageChange = (e: CustomEvent<string>) => {
      if (e.detail) {
        i18n.changeLanguage(e.detail);
      }
    };

    window.addEventListener('storage', handleLanguageChange);
    window.addEventListener(
      'mpharmaI18n',
      handleCustomLanguageChange as EventListener
    );
    return () => {
      window.removeEventListener('storage', handleLanguageChange);
      window.removeEventListener(
        'mpharmaI18n',
        handleCustomLanguageChange as EventListener
      );
    };
  }, []);
  return (
    <MpharmaI18nContext.Provider
      value={{
        ready: ready,
        changeLanguage: changeLanguage,
        translateKey: translateKey,
        currentLanguage: i18n.language,
        availableLanguages: props.availableLanguages,
      }}
    >
      {React.Children.map(props.children, child => {
        const childItem = child as React.ReactElement;
        return React.cloneElement(childItem, { ...props });
      })}
    </MpharmaI18nContext.Provider>
  );
};

export const MpharmaI18nProvider: React.FC<MpharmaI18nProviderProps> = ({
  children,
  languages,
  ...props
}) => {
  const i18n = createI18n(languages);
  return (
    <I18nextProvider i18n={i18n}>
      <WrapperComponent
        {...props}
        availableLanguages={languages.map(({ key }) => key)}
      >
        {children}
      </WrapperComponent>
    </I18nextProvider>
  );
};

export const useMpharmaI18n = () => React.useContext(MpharmaI18nContext);

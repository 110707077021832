import i18nInstance from 'i18next';
import { Resource, i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from './types';

const isDevMode = process.env.NODE_ENV !== 'production';

export function createI18n(languages: Language[]): i18n {
  const resources: Resource = {};

  languages.forEach(lang => {
    resources[lang.key] = { translation: lang.translations } as Resource;
  });

  i18nInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        resources: resources,
        fallbackLng: {
          'en-GB': ['en'],
          'en-US': ['en'],
          'fr-FR': ['fr'],
        },
        interpolation: {
          escapeValue: false,
        },
        debug: isDevMode,

        ns: ['translation'],
        defaultNS: 'translation',
        react: {
          wait: false,
          bindI18n: 'languageChanged loaded',
          nsMode: 'default',
        },
        detection: {
          lookupQuerystring: 'lang',
          lookupCookie: 'mpharmaI18n',
          lookupLocalStorage: 'mpharmaI18n',
          lookupSessionStorage: 'mpharmaI18n',
        },
      },
      undefined
    )
    .then();

  return i18nInstance;
}
